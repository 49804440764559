<template>
  <div class="videolist">
    <div class="top">
      <div class="querybox">
        <div>
          <p>视频名称:</p>
          <el-input
            v-model="queryParams.title"
            placeholder="请输入视频名称"
            clearable
          ></el-input>
        </div>
        <div>
          <p>视频状态:</p>
          <el-select
            v-model="queryParams.videoStatus"
            placeholder="请选择视频状态"
            clearable
          >
            <el-option
              v-for="item in videoStatusList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="handlebox">
        <!-- <el-button type="primary" class="green-btn">一键全选</el-button>
        <el-button type="primary" class="blue-btn">一键通过</el-button>
        <el-button type="primary" class="red-btn">一键拒绝</el-button> -->
        <el-button
          type="primary"
          icon="el-icon-search"
          class="blue-btn"
          @click="search"
          >查询</el-button
        >
        <el-button
          type="primary"
          icon="el-icon-refresh-right"
          class="gray-btn"
          style="color: #1d2129"
          @click="resetFn"
          >重置</el-button
        >
        <el-button
          type="primary"
          class="blue-btn"
          icon="el-icon-delete"
          style="background: #f96868"
          @click="deteleVideos"
          >删除</el-button
        >
      </div>
    </div>
    <div class="content">
      <el-table
        :data="tableData"
        tooltip-effect="dark"
        :header-cell-style="{ 'text-align': 'center' }"
        :cell-style="{ 'text-align': 'center' }"
        show-overflow-tooltip
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column prop="cover" label="视频封面">
          <template slot-scope="{ row }">
            <div class="logo">
              <img :src="row.cover" alt="" />
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="userName" label="发布人"> </el-table-column>
        <el-table-column prop="title" label="视频名称" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="createdTime" label="发布时间" min-width="120">
        </el-table-column>
        <el-table-column prop="likeCount" label="点赞数"> </el-table-column>
        <el-table-column prop="videoViews" label="浏览数"> </el-table-column>
        <el-table-column prop="commentCount" label="评论数">
          <template slot-scope="{ row }">
            {{ row.commentCount ? row.commentCount : 0 }}
          </template>
        </el-table-column>
        <el-table-column prop="epId" label="视频状态">
          <template slot-scope="{ row }">
            <p
              :style="{
                color: Number(row.videoStatus) === 1 ? '#5E83F5' : '#EE3A3A',
              }"
            >
              {{ row.videoStatus_dictText }}
            </p>
          </template>
        </el-table-column>
        <el-table-column label="操作" min-width="100">
          <template slot-scope="scope">
            <el-popconfirm
              title="是否上架该视频？"
              popper-class="customerdel-popover"
              @confirm="cancelOrder(scope.row, 1)"
              v-if="Number(scope.row.videoStatus) === 3"
            >
              <el-button class="blue-btn" slot="reference">上架</el-button>
            </el-popconfirm>
            <el-popconfirm
              title="是否下架该视频？"
              popper-class="customerdel-popover"
              @confirm="cancelOrder(scope.row, 3)"
              v-if="Number(scope.row.videoStatus) === 1"
            >
              <el-button class="red-btn" slot="reference">违规下架</el-button>
            </el-popconfirm>
            <el-button
              class="text-btn"
              type="text"
              @click="checkDetail(scope.row)"
              >查看视频</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页组件 -->
      <pagination
        v-show="total > queryParams.pageSize"
        :total="total"
        :page.sync="queryParams.pageNum"
        :limit.sync="queryParams.pageSize"
        @pagination="init"
      />
    </div>
    <!-- 查看视频 -->
    <el-dialog
      :visible.sync="videoVisible"
      :title="videoItem.title"
      width="40%"
      :before-close="handleClose"
      destroy-on-close
    >
      <div class="imgBox">
        <video :src="videoItem.video" controls />
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getVideoList, editVideoStatus, deleteVideo } from "@/api/video";
export default {
  data() {
    return {
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        title: "",
        videoStatus: null,
        listType: 1,
      },
      videoStatusList: [
        {
          value: 1,
          label: "正常显示",
        },
        {
          value: 3,
          label: "违规禁止显示",
        },
      ],
      tableData: [],
      total: 0, //总数
      videoItem: {},
      videoVisible: false,
      videoStatusLog: "",
      selectList: [],
    };
  },
  created() {
    this.init();
  },
  mounted() {},
  methods: {
    //获取审核列表
    async init() {
      const res = await getVideoList(this.queryParams);
      this.tableData = res.result.records;
      this.total = res.result.total;
    },
    // 查询
    search() {
      this.queryParams.pageNum = 1;
      this.init();
    },
    //重置
    resetFn() {
      this.queryParams.videoStatus = null;
      this.queryParams.title = "";
      this.queryParams.pageNum = 1;
      this.init();
    },
    // 查看视频
    checkDetail(row) {
      this.videoItem = row;
      this.videoVisible = true;
    },
    handleClose() {
      this.videoVisible = false;
    },
    async cancelOrder(item, status, index) {
      const params = {
        videoStatus: status,
        id: item.id,
        videoStatusLog: status === 2 ? this.videoStatusLog : null,
      };
      await editVideoStatus(params);
      if (index) {
        this.$refs[`popover-${index}`].doClose();
      }
      this.init();
    },
    handleSelectionChange(val) {
      // console.log(val);
      this.selectList = val;
    },
    async deteleVideos() {
      if (this.selectList.length === 0) {
        this.$message.error("请选择要删除的视频");
        return;
      }
      const arr = this.selectList.map((el) => {
        return el.id;
      });
      await deleteVideo(arr);
      this.$message.success("删除成功");
      this.queryParams.pageNum = 1;
      this.init();
    },
  },
};
</script>
<style lang="scss" scoped>
.videolist {
  .top {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #0000000d;
    padding-bottom: 10px;
    ::v-deep .querybox {
      display: flex;
      align-items: center;

      > div {
        display: flex;
        align-items: center;
        margin-right: 22px;
        p {
          color: #4e5969;
          line-height: 22px;
          margin-right: 16px;
          white-space: nowrap;
        }
        .el-input,
        .el-range-editor {
          width: 256px;
        }
        input::-webkit-input-placeholder {
          /* WebKit browsers */
          color: #86909c;
        }
        .el-input__inner,
        .el-range-input {
          background: #f7f8fa;
          border: none;
          color: #86909c;
        }
      }
      .btnbox {
        display: flex;
        .el-button {
          padding: 10px 0;
          width: 84px;
          box-sizing: border-box;
          // margin-left: 22px;
        }
      }
    }
    .handlebox {
      display: flex;
      align-items: center;
      .el-button {
        padding: 10px 0;
        width: 105px;
        box-sizing: border-box;
        // margin-left: 22px;
      }
    }
  }
  .content {
    padding-top: 20px;
    .logo {
      display: flex;
      justify-content: center;
      img {
        width: 47px;
        height: 47px;
        border-radius: 2px;
      }
    }
    .el-button {
      width: 80px;
      padding: 5px 0;
      box-sizing: border-box;
    }
  }
  ::v-deep(.el-dialog) {
    .el-dialog__title {
      text-align: center;
      left: 50%;
      transform: translateX(-50%);
    }
    .el-dialog__body {
      display: flex;
      align-items: center;
      justify-content: center;
      .imgBox {
        width: 100%;
        video {
          width: 100%;
        }
      }
      .OutBtnBox {
        margin-top: 10px;
        text-align: center;
      }
    }
  }
  .textred-btn,
  .textblue-btn {
    padding: 0 5px;
  }
}
</style>
